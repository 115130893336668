import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/Login.vue";
import callOfferRoutes from "@/router/callOffer";
import Contact from "@/views/Contact.vue";
import Profil from "@/views/Profil.vue";
import contractRoutes from "@/router/contract";
import devisRoutes from "@/router/devis";
import sinisterRoutes from "@/router/sinistre";
import VerifyEmail from "@/views/VerifyEmail.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import adminRoutes from "@/router/admin";
import TokenAuth from "@/views/TokenAuth.vue";

Vue.use(VueRouter)

let routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/auto/login',
        name: 'auto-login',
        component: TokenAuth,
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {requiredRoles: ['ROLE_CUSTOMER']},
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: {requiredRoles: ['ROLE_CUSTOMER']},
    },
    {
        path: '/profil',
        name: 'profil',
        component: Profil,
        meta: {requiredRoles: ['ROLE_CUSTOMER']},
    },
    {
        path: '/forgot/password',
        name: 'forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/reset/password/:uuid',
        name: 'reset-password',
        component: ResetPassword,
    },
    {
        path: '/verify/email/:mail',
        name: 'verify-email',
        component: VerifyEmail,
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

routes = routes.concat(callOfferRoutes).concat(contractRoutes).concat(devisRoutes).concat(sinisterRoutes).concat(adminRoutes)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
