



































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CallOffer from "@/entity/CallOffer";
import FileUpload from "@/components/FileUpload.vue";
import Popup from "@/entity/Popup";

@Component({
    components: {FileUpload}
})
export default class BranchAndInsurerSelection extends Vue {
    @Prop() callOffer!: CallOffer
    isBrancheToggle = false
    isInsurerToggle = false

    save(){
        this.$emit('save')
    }
    updateDraft(uuid: string) {
        this.callOffer.draft = uuid as any
        this.save()
    }

    toggleBranches() {
        let isAllChecked = true
        this.callOffer.branches.forEach((b) => {
            if (!b.selected) {
                isAllChecked = false
            }
        })
        if (!isAllChecked) {
            this.callOffer.branches.forEach((b) => {
                b.selected = true
            })
        } else {
            this.callOffer.branches.forEach((b) => {
                b.selected = false
            })
        }
    }


    mounted() {
        let isBranches = true
        let isInsurers = true
        this.callOffer.branches.forEach((b) => {
            if (!b.selected) {
                isBranches = false
            }
        })
        if (isBranches) {
            this.isBrancheToggle = true
        }

        this.callOffer.insurers.forEach((i) => {
            if (!i.selected) {
                isInsurers = false
            }
        })
        if (isInsurers) {
            this.isInsurerToggle = true
        }
    }

    next() {
        let branchSelected = 0
        let insurerSelected = 0
        this.callOffer.branches.forEach((b) => {
            if (b.selected) {
                branchSelected++
            }
        })
        if (branchSelected === 0) {
            let popup = new Popup('Erreur', 'Veuillez selectionner au moins une branche', 'danger')
            return
        }
        this.callOffer.insurers.forEach((i) => {
            if (i.selected) {
                insurerSelected++
            }
        })
        if (insurerSelected === 0) {
            let popup = new Popup('Erreur', 'Veuillez selectionner au moins un assureur', 'danger')
            return
        }
        // console.log(this.callOffer.expiration)
        if (!this.callOffer.expireAt) {
            let popup = new Popup('Erreur', 'Veuillez selectionner une date de clotûre', 'danger')
            return
        }

        if (!this.callOffer.useForm && !this.callOffer.draft) {
            let popup = new Popup('Erreur', 'Veuillez nous faire parvenir votre document d\'appel d\'offre', 'danger')
            return
        }
        if (branchSelected > 0 && insurerSelected > 0) {
            this.$emit('next')
        }
    }

    toggleInsurers() {
        let isAllChecked = true
        this.callOffer.insurers.forEach((i) => {
            if (!i.selected) {
                isAllChecked = false
            }
        })
        if (!isAllChecked) {
            this.callOffer.insurers.forEach((i) => {
                i.selected = true
            })
        } else {
            this.callOffer.insurers.forEach((i) => {
                i.selected = false
            })
        }
    }
}
