



































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import {auth} from "@/services/Auth";
import Customer from "@/entity/Customer";
import Popup from "@/entity/Popup";
// import {api} from "@/services/Api";

@Component({
    components: {}
})
export default class Login extends Vue {
    [x: string]:
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
        any;

    private username = '';
    private password = '';
    public isLoading = false;
    public reset = false;
    private error = '';
    customer: any = null;
    accountInactive = false
    passwordRepeated: string = ''

    subscribe() {
        this.customer = new Customer()
    }

    cancelSubscription() {
        this.customer = null
    }

    async createAccount() {
        if (this.isLoading) {
            return
        }
        this.isLoading = true;
        if (this.customer instanceof Customer) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(this.customer.email).toLowerCase())) {
                new Popup(this.trans('Error'), this.trans('Please_enter_a_valide_email_address'), 'warning', 'fad fa-exclamation-triangle');
                this.isLoading = false;
                return
            }
            if (!this.customer.password || this.customer.password.trim().length === 0) {
                new Popup(this.trans('Error'), this.trans('Please_choose_a_password'), 'warning', 'fad fa-exclamation-triangle');
                this.isLoading = false;
                return
            }
            if (this.customer.password !== this.passwordRepeated) {
                new Popup(this.trans('Error'), this.trans('Password_does_not_match'), 'warning', 'fad fa-exclamation-triangle');
                this.isLoading = false;
                return
            }
        }
        const load = new Popup(this.trans('Registering'), this.trans('We_are_creating_your_account'), 'primary', 'fad fa-cogs', true);
        const res = await api.post('shared/customer/register', {
            email: this.customer.email,
            password: this.customer.password
        })
        load.hide()
        if (res && res.data && res.data.status === 'success') {
            // new Popup(this.trans('Success'), this.trans('Your_account_has_been_created'), 'success', 'fad fa-thumbs-up');
            const buttons = [
                {
                    class: 'btn btn-sm btn-danger',
                    text: this.trans('Renvoyer le mail'),
                    textClass: '',
                    icon: 'fad fa-paper-plane',
                    action: 'sendCustomerAccountValidationEmail',
                    click: 'sendCustomerAccountValidationEmail',
                    data: this.customer
                },
            ]
            this.$router.push({name : 'verify-email',params : {mail : this.customer.email }})
            // console.log(res);
            // this.$store.state.popups = []
            // new Popup(this.trans('Activez votre compte'),
            //     this.trans('Nous venons d’envoyer un e-mail à') + ' ' + this.customer.email + ' avec un lien.' +
            //     this.trans('Vous serez invité à cliquer sur ce lien pour confirmer votre e-mail et finaliser la création de votre espace client. Merci.'),
            //     'warning', 'fad fa-exclamation-triangle', true,
            //     undefined, buttons);
            // this.customer = null
            // this.activated = true
            // this.activate = true
            // this.showBlocs = false
        }
        this.isLoading = false;
    }

    showBlocs = true

    public async signin(): Promise<void> {
        this.$store.state.popups = []
        if (this.isLoading) {
            return
        }
        this.isLoading = true;
        if (!this.username.length) {
            this.error = 'Entrez votre nom d\'utilisateur';
            this.isLoading = false;
        }
        if (!this.password.length) {
            this.error = 'Entrez votre mot de passe';
            this.isLoading = false;
        }
        if (!this.error) {
            const ok = await auth.login(this.username, this.password);
            this.isLoading = false;
            if (ok) {
                // router.push({name: 'home'})
            }
        }
        this.isLoading = false;
        this.$forceUpdate();
    }

    // async resetPass(): Promise<void> {
    //     this.isLoading = true;
    //     this.$store.commit('load');
    //     const res = await api.post('shared/reset/password', {email: this.username});
    //     this.$store.commit('stopLoad');
    //     this.isLoading = false;
    //     this.$forceUpdate();
    // }

    logout() {
        api.logout();
    }

    get isConnected(): boolean {
        return api.isConnected();
    }


    public activated = false

    mounted() {
        console.log(this.$route.query)
        if (this.$route.query.hasOwnProperty('status') && this.$route.query.status === 'activated') {
            this.activated = true
            new Popup(this.trans('EMAIL VERIFIE'),
                this.trans('Merci d\'avoir vérifié votre adresse mail, votre compte a été créé. Vous pouvez maintenant vous identifier.'),
                'success', 'fad fa-thumbs-up', true);
        }
        if (this.$route.query.hasOwnProperty('subscribe') && this.$route.query.subscribe === 'true') {
            this.subscribe()
        }
        if (this.$route.query.hasOwnProperty('status') && this.$route.query.status === 'active') {
            this.activated = true
        }

        const buttons = [
            {
                class: 'btn btn-sm btn-danger',
                text: this.trans('Renvoyer le mail'),
                textClass: '',
                icon: 'fad fa-paper-plane',
                action: 'sendCustomerAccountValidationEmail',
                click: 'sendCustomerAccountValidationEmail',
                data: this.customer
            },
        ]
        auth.logout();
    }
}
