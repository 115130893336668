import {RouteConfig} from "vue-router";
import ContractHome from "@/views/enterprise/Contract/ContractHome.vue";


const prefix = '/contracts'
const contractRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'contract-index',
        component: ContractHome,
        meta: {requiredRoles: ['ROLE_CUSTOMER']},
    },
]
contractRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default contractRoutes;
