


































































import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import Popup from "@/entity/Popup";

@Component({})
export default class VerifyEmail extends Vue {
    [x: string]: any;

    email = ''
    sent = false

    mounted() {
        this.email = this.$route.params.mail
    }

    async resend() {
        this.$store.commit('loading')
        const res = await api.get('shared/customer/send/account/validation/mail/' + this.email)
        if (res && res.data) {
            new Popup('Email Envoyé',
                this.trans('Si le compte "<b>' + this.email + '</b>" existe chez WiASSUR un email contenant un lien y sera envoyé.')
                + this.trans(' Cliquer sur le lien pour confirmer votre e-mail et finaliser la création de votre espace client.')
                + '<br> <span style="color:indianred    ;font-weight: bolder">' +
                this.trans('Si vous ne l\'avez pas reçu, pensez à vérifier vos spams')
                + '</span>','success','fad fa-thumbs-up',true)

            this.sent = true
        }
        this.$store.commit('stopLoading')
    }
}
