import {RouteConfig} from "vue-router";
import CallOfferHome from "@/views/enterprise/CallOffer/CallOfferHome.vue";
import CallOfferParameter from "@/views/enterprise/CallOffer/CallOfferParameter.vue";
import CallOfferQuery from "@/views/enterprise/CallOffer/Query/CallOfferQuery.vue";
import CallOfferNew from "@/views/enterprise/CallOffer/CallOfferNew.vue";
import CallOfferEdit from "@/views/enterprise/CallOffer/Query/CallOfferEdit.vue";
import CallOfferResponse from "@/views/enterprise/CallOffer/Query/CallOfferResponse.vue";
import CallOfferList from "@/views/admin/CallOfferList.vue";


const prefix = '/call/offer'
const callOfferRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'call-offer-index',
        component: CallOfferHome,
        meta: {requiredRoles: ['ROLE_ENTERPRISE_MANAGER']},
    },
    {
        path: '/new',
        name: 'call-offer-new',
        component: CallOfferNew,
        meta: {requiredRoles: ['ROLE_ENTERPRISE_MANAGER']},
    },
    {
        path: '/parameter',
        name: 'call-offer-parameter',
        component: CallOfferParameter,
        meta: {requiredRoles: ['ROLE_ENTERPRISE_MANAGER']},
    },
    {
        path: '/edit/:uuid',
        name: 'call-offer-edit',
        component: CallOfferEdit,
        meta: {requiredRoles: ['ROLE_ENTERPRISE_MANAGER']},
    },
    {
        path: '/form/:uuid',
        name: 'call-offer-form',
        component: CallOfferQuery,
        meta: {requiredRoles: ['ROLE_ENTERPRISE_MANAGER']},
    },
    {
        path: '/response/:uuid',
        name: 'call-offer-response',
        component: CallOfferResponse,
        meta: {requiredRoles: ['ROLE_ENTERPRISE_MANAGER']},
    },


]
callOfferRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default callOfferRoutes;
