






































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import Customer from "@/entity/Customer";
import Phone from "@/entity/Phone";
import Enterprise from "@/entity/Enterprise";

@Component({})
export default class EnterpriseInfo extends Vue {
    customer:any = null
    enterprise: any;
    isIdentified = false

    async save() {

        if (this.validate()) {
            this.$store.commit('loading')
            const res = await api.post('selfcare/customer/update', {
                customer: this.customer
            })
            this.$emit('customer', res.data.customer)
            this.$store.commit('stopLoading')
        } else {
            console.log('not validate')
        }
    }

    async mounted() {
        await this.getEnterpriseInfo()
        if (this.identify()) {
            this.isIdentified = true
        }
        while (this.customer.phones.length < 1) {
            const phone = new Phone()
            phone.customer = this.customer;
            this.customer.phones.push(phone)
        }
    }

    async getEnterpriseInfo() {
        const res = await api.get('selfcare/customer/infos')
        if (res && res.data && res.data.customer) {
            this.$store.state.user = new Customer(res.data.customer);
        }
        this.customer = this.$store.state.user as Customer;
        if (this.customer.enterprise) {
            this.enterprise = this.customer.enterprise
        } else {
            this.enterprise = new Enterprise()
            this.customer.enterprise = this.enterprise
        }
        if (this.identify()) {
            this.isIdentified = true
        }
    }

    identify() {
        return this.customer instanceof Customer && this.enterprise instanceof Enterprise
            && this.customer.isIdentified() && this.enterprise.isIdentified()
    }

    validate() {
        return this.customer instanceof Customer && this.customer.enterprise instanceof Enterprise
            && this.customer.validate() && this.customer.enterprise.validate()
    }

    addPhone() {
        let ok = true
        this.customer.phones.forEach((p:Phone) => {
            if (this.empty(p.number)) {
                ok = false
            }
        })
        if (!ok) {
            return
        }
        const phone = new Phone()
        phone.customer = this.customer;
        this.customer.phones.push(phone)
    }

    removePhone(phone: Phone) {
        console.log(phone)
        const index = this.customer.phones.findIndex((p: Phone) => {
            return p.number === phone.number
        })
        // console.log(index)
        if (index !== -1) {
            this.customer.phones.splice(index, 1)
        }
    }

    isValid() {
        const customer = this.customer
        const enterprise = customer.enterprise
        if (!enterprise) {
            return false
        }
        return this.empty(customer.civility) || this.empty(customer.name) || this.empty(customer.prename)
            || this.empty(enterprise.label) || this.empty(enterprise.rccm) || this.empty(enterprise.activity)
    }

    empty(data: string | undefined): boolean {
        return data === null || data === undefined || data.length === 0
    }

}
