import {RouteConfig} from "vue-router";
import SinistreHome from "@/views/enterprise/Sinistre/SinistreHome.vue";
import SinistreNew from "@/views/enterprise/Sinistre/SinistreNew.vue";


const prefix = '/sinistre'
const sinisterRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'sinistre-index',
        component: SinistreHome,
        meta: {requiredRoles: ['ROLE_CUSTOMER']},
    },
    {
        path: '/new',
        name: 'sinistre-new',
        component: SinistreNew,
        meta: {requiredRoles: ['ROLE_CUSTOMER']},
    },
]
sinisterRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default sinisterRoutes;
