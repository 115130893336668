






















































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import {uploadService} from "@/services/UploadService";
import File from "@/entity/File";
import {api} from "@/services/Api";

@Component({})
export default class FileUpload extends Vue {
    @Prop() allowMultiple?: boolean;
    @Prop() allowDownload?: boolean;
    @Prop() allowRemove?: boolean;
    @Prop() showFullName?: boolean;
    @Prop() currentFile ?: File;
    // publicUrl = process.env.VUE_APP_BACKEND_URL;
    @Prop() fitMode ?: string;
    @Prop() accept ?: string;
    @Prop() data ?: any;
    @Prop() allowedType ?: string;
    @Prop() text ?: string;
    @Prop() type ?: string;
    @Prop() icon ?: string;
    @Prop() previewStyle?: string;
    @Prop() buttonStyle?: string;
    @Prop() currentImageLink?: string;
    public types: string[] = [];
    message = 'Selectionnez un fichier';
    error = false;
    background: any = '';
    public file: any = null;
    folder = null;
    percent = 0;
    success = false;
    @Prop() uuid ?: string;
    isUploading = false;
    fileId = '';
    inputId = '';


    get excerpt(): string {
        if (this.file) {
            if (this.showFullName) {
                return this.file.name;
            }
            if (this.file instanceof File) {
                return this.file.excerpt;
            }
            let ln = '';
            if (this.file.name.length > 30) {
                ln = this.file.name.substring(0, 20);
                ln += '...' + this.file.name.substring((this.file.name.length - 5), this.file.name.length);
                return ln;
            }
            return this.file.name;
        }
        return '';
    }

    async loadFile(uuid: string) {
        const res = await api.get('selfcare/uploader/get/' + uuid)
        // console.log(res)
        if (res && res.data && res.data.file) {
            this.file = new File(res.data.file)
        }
    }

    mounted(): void {
        if (this.currentFile) {
            this.file = this.currentFile;
        }
        if (!this.currentFile && this.data && this.data.uuid && !this.file) {
            this.loadFile(this.data.uuid)
        }

        this.inputId = uploadService.generateId();
        if (this.allowedType) {
            this.types = this.allowedType.split(',');
        }

        if (this.currentImageLink) {
            this.background = this.currentImageLink;
        }

        uploadService.on('uploadStart', (data: any) => {

            if (data.id === this.fileId) {
                this.percent = data.percent;
            }
        });
        uploadService.on('chunkSent', (data: any) => {

            if (data.id === this.fileId) {
                console.log(data.id);
                this.percent = data.percent;
            }
        });
        uploadService.on('uploadSuccess', (data: any) => {

            if (data.id === this.fileId) {
                this.isUploading = false;
                this.error = false;
                this.success = true;
                this.percent = 100;
                // this.setUuid(data.serverFileId);
                this.$emit('uuid', data.serverFileId);
            }
        });
        uploadService.on('error', (data: any) => {

            if (data.id === this.fileId) {
                // const error = data.xhr.error;
                this.isUploading = false;
                this.error = true;
                this.message = 'Une erreur a été rencontré lors du téléversement, veuillez réessayer plus tard';
            }
        });
    }

    async downloadFile(): Promise<void> {
        if (this.file instanceof File) {
            await api.downloadFile(this.file);
        }

    }

    drop() {
        console.log('drop');
    }

    allowDrop() {
        console.log('allow drop');
    }


    setUuid(uuid: string) {
        this.uuid = uuid;
    }

    cancelUpload() {
        uploadService.cancel(this.fileId);
        if (this.file instanceof File) {
            this.$emit('remove', this.file.uuid);
        }
        this.file = null;
        this.fileId = '';
        this.isUploading = false;
        this.percent = 0;
        this.error = false;
        this.success = false;
        this.background = '';
    }

    uploadFile(event: Event): void {
        this.error = true;
        let files = (event.target as any).files;
        if (files.length <= 0) {
            this.message = 'Selectionnez un fichier';
            return;
        }
        const file = files[0] as any;

        if (this.types.length > 0) {
            if (this.types.indexOf(file.type) === -1) {
                this.error = true;
                this.message = 'Format ' + file.type + ' non autorisé';
                console.log(this.message);
                return;
            }
        }
        this.file = file;

        if (this.previewStyle) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.background = reader.result;
            });
            reader.readAsDataURL(file);
        }
        this.isUploading = true;
        this.error = false;
        this.success = false;

        this.fileId = uploadService.generateId();
        uploadService.upload(file, this.fileId, undefined, this.data);
    }

}
