/* eslint-disable */
import Customer from "./Customer";
import PhoneExtend from "@/entity/extends/PhoneExtend";

export default class Phone extends PhoneExtend {

public id!: number;
public number = '';
public customer?: Customer;

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.number= object.number;
this.customer = (object.customer instanceof Customer) ? object.customer : new Customer(object.customer);
      }
  }

}
