export const FILTER_API_DATE_FORMAT = 'YYYYMMDDHHmmss';
export const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';
export const TABLE_DISPLAY_DATE_FORMAT = 'MM/DD/YYYY h:mm:ssA';

class Helper {


    formatCurrency(amount: string) {
        amount = '' + Math.ceil(parseInt(amount));
        if (amount === undefined) {
            return amount;
        }
        return amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    }

    readable(date: Date | string | undefined, showMinutes = false, minimize = false, showDay = true): string {

        // console.log(date);
        if (typeof date === 'string') {
            try {
                date = new Date(date);
            } catch (e) {
                console.log('error');
                console.log(e);
                if (typeof date === 'string') {
                    return date;
                }
                return '';
            }
        }
        if (date instanceof Date) {
            let res = date.getDate() + ' ';
            if (!showDay) {
                res = '';
            }

            let month = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
            if (minimize) {
                month = ['Janv', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'];
            }
            res += month[date.getMonth()] + ' ';
            if (minimize) {
                res += (date.getFullYear() + '').substr(-2);
            } else {
                res += date.getFullYear();
            }
            let h = date.getHours() < 10 ? '0' : '';
            let m = date.getMinutes() < 10 ? '0' : '';
            if (showMinutes) {
                res += ' à ' + h + date.getHours() + ':' + m + date.getMinutes();
            }
            // console.log(res);
            return res;
        }
        return '';
    }

    getTime(date: Date | string | undefined, showHours = true, showMinutes = true, showSeconds = true): string {

        if (typeof date === 'string') {
            try {
                date = new Date(date);
            } catch (e) {
                console.log('error');
                console.log(e);
                if (typeof date === 'string') {
                    return date;
                }
                return '';
            }
        }
        if (date instanceof Date) {
            let res = '';

            let h = date.getHours() < 10 ? '0' : '';
            let m = date.getMinutes() < 10 ? '0' : '';
            if (showHours) {
                res += h + date.getHours()
            }
            if (showMinutes) {
                res += ':' + m + date.getMinutes()
            }
            return res;
        }
        return '';
    }


    getTimeLapse(date?: Date | string | undefined, now?: Date | string, addPrefix = true, format ?: string): string {

        if (typeof now === 'string') {
            try {
                now = new Date(now);
            } catch (e) {
                throw new Error('Impossible de convertir ' + now + ' en date valide');
            }
        }
        if (!now) {
            now = new Date();
        }
        if (typeof date === 'string') {
            try {
                date = new Date(date);
            } catch (e) {
                throw new Error('Impossible de convertir ' + date + ' en date valide');
            }
        }
        if (!date) {
            return '';
        }
        const diff = Math.abs(now.getTime() - date.getTime());
        let text = '';
        const timelapse = {
            y: Math.floor(diff / 1000 / 60 / 60 / 24 / 30 / 12),
            m: Math.floor(diff / 1000 / 60 / 60 / 24 / 30) % 12,
            d: Math.floor(diff / 1000 / 60 / 60 / 24) % 30,
            h: Math.floor(diff / 1000 / 60 / 60) % 24,
            i: Math.floor(diff / 1000 / 60) % 60,
            s: Math.floor(diff / 1000) % 60,
        };
        if (text.trim() === '' || (format && format.includes('y'))) {
            text += timelapse.y > 0 ? timelapse.y + ' an' : '';
            text += timelapse.y > 1 ? 's' : '';
        }
        if (text.trim() === '' || (format && format.includes('m'))) {
            text += timelapse.m > 0 ? ' ' + timelapse.m + ' ' + 'mois' : '';
        }
        if (text.trim() === '' || (format && format.includes('d'))) {
            text += timelapse.d > 0 ? ' ' + timelapse.d + ' jour' : '';
            text += timelapse.d > 1 ? 's' : '';
        }
        if (text.trim() === '' || (format && format.includes('h'))) {
            text += timelapse.h > 0 ? ' ' + timelapse.h + ' heure' : '';
            text += timelapse.h > 1 ? 's' : '';
        }
        if (text.trim() === '' || (format && format.includes('i'))) {
            text += timelapse.i > 0 ? ' ' + timelapse.i + ' minute' : '';
            text += timelapse.i > 1 ? 's' : '';
        }
        if (text.trim() === '' || (format && format.includes('s'))) {
            text += timelapse.s > 0 ? ' ' + timelapse.s + ' seconde' : '';
            text += timelapse.s > 1 ? 's' : '';
        }
        // console.log(text);

        if (addPrefix) {
            const prefix = now.getTime() - date.getTime() > 0 ? 'Il y a ' : 'Dans ';
            text = prefix + text;
        }
        // console.log(text);
        return text;
    }
}

export const helper = new Helper()
