








































































































































































import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Customer from "@/entity/Customer";
import Loading from "@/components/Loading.vue";
import Pagination from "@/components/Pagination.vue";
import CallOffer from "@/entity/CallOffer";

@Component({
    components: {Pagination, Loading, Base}
})
export default class Customerlist extends Vue {
    customers: Customer[] = []
    callOffers: CallOffer[] = []
    matchCount = 0
    loading = false
    count = 0
    filter = {
        page: 1,
        perPage: 50,
        branch: null,
        dateIn: null,
        dateOut: null,
        timeIn: null,
        timeOut: null,
        contain: null,
        hasResponseTo: null,
    }


    mounted(): void {
        this.loadCustomers()
    }

    async loadCustomers() {
        this.loading = true
        const res = await api.get('selfcare/manager/customer/list')
        if (res && res.data && res.data.customers) {
            this.customers = []
            res.data.customers.forEach((c: Customer) => {
                this.customers.push(new Customer(c))
            })
        }
        this.loading = false
    }

    async deleteCallOffer(uuid: string) {
        const res = await api.get('selfcare/manager/delete/call/offer/' + uuid);
        this.loadCustomers()
    }

    async loadCallOffers() {
        const res = await api.get('selfcare/call/offer/full/list');
        if (res && res.data && res.data.callOffers) {
            res.data.callOffers.forEach((callOffer: CallOffer) => {
                this.callOffers.push(new CallOffer(callOffer))
            })
        }
    }
}
