







import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import EnterpriseInfo from "@/views/enterprise/CallOffer/EnterpriseInfo.vue";

@Component({
    components: {EnterpriseInfo, Base}
})
export default class Profil extends Vue {
}
