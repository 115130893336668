





































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import BranchAndInsurerSelection from "@/views/enterprise/CallOffer/Query/Steps/BranchAndInsurerSelection.vue";
import Popup from "@/entity/Popup";
import CallOffer from "@/entity/CallOffer";
import CallOfferCarInsurance from "@/views/enterprise/CallOffer/Query/Steps/CallOfferCarInsurance.vue";
import CallOfferMultirisquePro from "@/views/enterprise/CallOffer/Query/Steps/CallOfferMultirisquePro.vue";
import MultirisqueOnlineForm from "@/views/enterprise/CallOffer/Query/Steps/MultirisqueOnlineForm.vue";
import CallOfferRcPro from "@/views/enterprise/CallOffer/Query/Steps/CallOfferRcPro.vue";
import RcProOnlineForm from "@/views/enterprise/CallOffer/Query/Steps/RcProOnlineForm.vue";
import CallOfferSante from "@/views/enterprise/CallOffer/Query/Steps/CallOfferSante.vue";
import CallOfferIac from "@/views/enterprise/CallOffer/Query/Steps/CallOfferIac.vue";
import {api} from "@/services/Api";
import CallOfferResults from "@/views/enterprise/CallOffer/Query/Steps/CallOfferResults.vue";
import {auth} from "@/services/Auth";

@Component({
    components: {
        CallOfferResults,
        CallOfferIac,
        CallOfferSante,
        RcProOnlineForm,
        CallOfferRcPro,
        MultirisqueOnlineForm,
        CallOfferMultirisquePro, CallOfferCarInsurance, BranchAndInsurerSelection, Base
    }
})
export default class CallOfferQuery extends Vue {

    public callOffer: CallOffer = new CallOffer()
    public legacy: CallOffer = new CallOffer()
    public steps = [
        {
            id: 1, icon: 'fad fa-file-signature', title: 'BranchAndInsurerSelection', enable: true, condition: true,
            showHeader: true,
            name: 'Nouvelle demande'
        },
        {
            id: 2,
            icon: 'fad fa-car-crash',
            title: 'CarInsurance',
            name: 'Auto',
            showHeader: true,
            enable: true,
            condition: 'this.isBranchSelected(\'auto\')'
        },
        {
            id: 3,
            icon: 'fad fa-car-building',
            title: 'Multirisque',
            name: 'Multirisque',
            showHeader: true,
            enable: true,
            condition: 'this.isBranchSelected(\'multirisque\')'
        },
        // {
        //     id: 4,
        //     icon: 'fad fa-ballot-check',
        //     title: 'MultirisqueOnlineForm',
        //     showHeader: false,
        //     name: 'Formulaire Multirisque',
        //     enable: false,
        //     condition: 'true'
        // },
        {
            id: 4,
            icon: 'fad fa-handshake',
            title: 'rcPro',
            name: 'RC Pro',
            enable: true,
            showHeader: true,
            condition: 'this.isBranchSelected(\'rcPro\')'
        },
        // {
        //     id: 6,
        //     icon: 'fad fa-ballot-check',
        //     title: 'rcProOnlineForm',
        //     name: 'Formulaire RC Pro',
        //     showHeader: false,
        //     enable: false,
        //     condition: 'true'
        // },
        {
            id: 5,
            icon: 'fad fa-stethoscope',
            title: 'sante',
            name: 'Santé',
            enable: true,
            showHeader: true,
            condition: 'this.isBranchSelected(\'sante\')'
        },
        {
            id: 6,
            icon: 'fad fa-wheelchair',
            title: 'iac',
            name: 'IAC',
            enable: true,
            showHeader: true,
            condition: 'this.isBranchSelected(\'iac\')'
        },
        {
            id: 7,
            icon: 'fad fa-ballot-check',
            title: 'results',
            name: 'Resultats',
            enable: true,
            showHeader: true,
            condition: 'true'
        },
    ];
    public currentStep: any = this.steps[0]
    public key = 1

    hasRoles(roles: string[]) {
        return auth.hasRoles(roles)
    }

    isHeaderShown(step: any) {
        return step.enable && step.showHeader && eval(step.condition as string)
    }

    setStep(step: any) {
        this.currentStep = step
        this.save()
    }

    isStepValid(step: any) {
        if (step.id === 1) {
            return this.validateConfiguration()
        }
        if (step.id === 2) {
            return this.validateCar()
        }
        if (step.id === 3) {
            return this.validateMultirisque()
        }
        if (step.id === 4) {
            return this.validateRc()
        }
        if (step.id === 5) {
            return this.validateSante()
        }
        if (step.id === 6) {
            return this.validateIac()
        }

        return 'Incomplet'
    }

    validateConfiguration() {
        const callOffer = this.callOffer
        if (!callOffer.isBranchesSelected) {
            return 'Aucune branche d\'assurance n\'a été selectionnée'
        }
        if (!callOffer.isInsurerSelected) {
            return 'Aucun assureur n\'a été selectionné'
        }
        if (!callOffer.useForm && !callOffer.draft) {
            return 'Votre document d\'appel d\'offre est manquant'
        }
        if (!callOffer.expireAt) {
            return 'Veuillez spécifier une date de clotûre'
        }
        return true;
    }

    validateCar() {
        const callOffer = this.callOffer
        if (!callOffer.data.auto.list) {
            return 'La liste des véhicules est manquante'
        }
        return true
    }

    validateMultirisque() {
        const callOffer = this.callOffer
        if (!callOffer.data.mrp.online && !callOffer.data.mrp.form) {
            return 'Le formulaire est manquant'
        }
        if (callOffer.data.mrp.online) {
            return 'Le formulaire est incomplet'
        }

        return true
    }

    validateRc() {
        const callOffer = this.callOffer
        if (!callOffer.data.rcPro.online && !callOffer.data.rcPro.form) {
            return 'Le formulaire est manquant'
        }
        if (callOffer.data.rcPro.online) {
            return 'Le formulaire est incomplet'
        }

        return true
    }

    validateSante() {
        const callOffer = this.callOffer
        if (!callOffer.data.sante.list) {
            return 'La liste du personnel est manquante'
        }

        const colleges = callOffer.data.sante.colleges
        for (let i = 0; i < colleges.length; i++) {
            const college = colleges[i]
            let id = college.name
            if (this.empty(id)) {
                id = 'N°' + i
            }
            if (college.nombre <= 0) {
                return 'Entrez un nombre d\'assuré valide pour le collège "' + id + '"'
            }
            if (college.taux !== '80%' && college.taux !== '100%') {
                return 'Selectionnez un taux valide pour le collège "' + id + '"'
            }
            if (college.reseau !== 'Economique' && college.reseau !== 'Premium') {
                return 'Selectionnez un reseau valide pour le collège "' + id + '"'
            }
            if (this.empty(college.etendue)) {
                return 'Selectionnez une zone géographique valide pour le collège "' + id + '"'
            }
        }

        return true
    }

    validateIac() {
        const callOffer = this.callOffer
        if (!callOffer.data.iac.list) {
            return 'Le formulaire est manquant'
        }
        const capitaux = callOffer.data.iac.capitaux
        let selectCount = 0
        for (let i = 0; i < capitaux.length; i++) {
            const capital = capitaux[i]
            let id = capital.name
            if (capital.selected) {
                selectCount++
                if (capital.amount <= 0) {
                    return 'Entrez un montant pour le capital "' + id + '"'
                }
                if (capital.count <= 0) {
                    return 'Entrez un nombre d\'assuré valide pour le collège "' + id + '"'
                }
            }
        }
        if (selectCount === 0) {
            return 'Aucun capital n\'a été selectionné'
        }

        return true
    }

    empty(data: string | undefined): boolean {
        return data === null || data === undefined || data.length === 0
    }

    public isBranchSelected(code: string) {
        if (!this.callOffer.useForm) {
            return false
        }
        const b = this.callOffer.branches.find((b) => {
            return b.code === code
        })
        if (b) {
            return b.selected
        }
        return false
    }

    enableStep(name: string) {
        for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].title === name) {
                this.steps[i].enable = true
            }
        }
    }

    disableStep(name: string) {
        for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].title === 'name') {
                this.steps[i].enable = false
            }
        }
    }

    async mounted() {
        if (this.$route.params.uuid) {
            await this.load(this.$route.params.uuid);
        } else {
            this.callOffer = new CallOffer()
        }
    }


    async load(uuid: string) {
        this.$store.commit('loading')
        const res = await api.get('selfcare/call/offer/get/' + uuid)
        if (res && res.data && res.data.callOffer) {
            this.callOffer = new CallOffer(res.data.callOffer)
            this.legacy = new CallOffer(res.data.callOffer)
            this.currentStep = this.steps[6]
            this.key++
        }
        // console.log(this.callOffer)
        this.$store.commit('stopLoading')
    }

    async save() {
        let popup = new Popup('', 'Sauvegarde en cours', 'warning', 'fad fa-save', true)
        // if (JSON.stringify(this.callOffer) !== JSON.stringify(this.legacy)) {
        const callOffer = this.callOffer
        const res = await api.post('selfcare/call/offer/edit', {callOffer});
        popup.hide()
        if (res && res.data && res.data.callOffer && !this.$route.params.uuid) {
            this.$router.push({name: 'call-offer-form', params: {uuid: res.data.callOffer.uuid}})
        }
        // }else{
        //     console.log('no change')
        // }
    }

    next() {
        this.save()
        for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].id > this.currentStep.id && this.steps[i].enable && eval(this.steps[i].condition as string)) {
                this.currentStep = this.steps[i]
                return;
            }
        }
    }

    previous() {
        // console.log('previous')
        for (let i = (this.steps.length - 1); i >= 0; i--) {
            const step = this.steps[i];
            if (step.id < this.currentStep.id && step.enable && eval(step.condition as string)) {
                this.currentStep = step;
                return;
            }
        }
    }

}
