































































































































































import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import BranchAndInsurerSelection from "@/views/enterprise/CallOffer/Query/Steps/BranchAndInsurerSelection.vue";
import Popup from "@/entity/Popup";
import CallOffer from "@/entity/CallOffer";
import CallOfferCarInsurance from "@/views/enterprise/CallOffer/Query/Steps/CallOfferCarInsurance.vue";
import CallOfferMultirisquePro from "@/views/enterprise/CallOffer/Query/Steps/CallOfferMultirisquePro.vue";
import MultirisqueOnlineForm from "@/views/enterprise/CallOffer/Query/Steps/MultirisqueOnlineForm.vue";
import CallOfferRcPro from "@/views/enterprise/CallOffer/Query/Steps/CallOfferRcPro.vue";
import RcProOnlineForm from "@/views/enterprise/CallOffer/Query/Steps/RcProOnlineForm.vue";
import CallOfferSante from "@/views/enterprise/CallOffer/Query/Steps/CallOfferSante.vue";
import CallOfferIac from "@/views/enterprise/CallOffer/Query/Steps/CallOfferIac.vue";
import {api} from "@/services/Api";
import CallOfferResults from "@/views/enterprise/CallOffer/Query/Steps/CallOfferResults.vue";
import Customer from "@/entity/Customer";
import Enterprise from "@/entity/Enterprise";
import EnterpriseInfo from "@/views/enterprise/CallOffer/EnterpriseInfo.vue";

@Component({
    components: {
        EnterpriseInfo,
        CallOfferResults,
        CallOfferIac,
        CallOfferSante,
        RcProOnlineForm,
        CallOfferRcPro,
        MultirisqueOnlineForm,
        CallOfferMultirisquePro, CallOfferCarInsurance, BranchAndInsurerSelection, Base
    }
})
export default class CallOfferNew extends Vue {

    public callOffer: CallOffer = new CallOffer()
    public legacy: CallOffer = new CallOffer()
    public steps = [
        {
            id: 1, icon: 'fad fa-file-signature', title: 'BranchAndInsurerSelection', enable: true, condition: true,
            showHeader: true,
            name: 'Nouvelle demande'
        },
        {
            id: 2,
            icon: 'fad fa-car-crash',
            title: 'CarInsurance',
            name: 'Auto',
            showHeader: true,
            enable: true,
            condition: 'this.isBranchSelected(\'auto\')'
        },
        {
            id: 3,
            icon: 'fad fa-car-building',
            title: 'Multirisque',
            name: 'Multirisque',
            showHeader: true,
            enable: true,
            condition: 'this.isBranchSelected(\'multirisque\')'
        },
        // {
        //     id: 4,
        //     icon: 'fad fa-ballot-check',
        //     title: 'MultirisqueOnlineForm',
        //     showHeader: false,
        //     name: 'Formulaire Multirisque',
        //     enable: false,
        //     condition: 'true'
        // },
        {
            id: 4,
            icon: 'fad fa-handshake',
            title: 'rcPro',
            name: 'RC Pro',
            enable: true,
            showHeader: true,
            condition: 'this.isBranchSelected(\'rcPro\')'
        },
        // {
        //     id: 6,
        //     icon: 'fad fa-ballot-check',
        //     title: 'rcProOnlineForm',
        //     name: 'Formulaire RC Pro',
        //     showHeader: false,
        //     enable: false,
        //     condition: 'true'
        // },
        {
            id: 5,
            icon: 'fad fa-stethoscope',
            title: 'sante',
            name: 'Santé',
            enable: true,
            showHeader: true,
            condition: 'this.isBranchSelected(\'sante\')'
        },
        {
            id: 6,
            icon: 'fad fa-wheelchair',
            title: 'iac',
            name: 'IAC',
            enable: true,
            showHeader: true,
            condition: 'this.isBranchSelected(\'iac\')'
        },
        {
            id: 7,
            icon: 'fad fa-ballot-check',
            title: 'results',
            name: 'Resultats',
            enable: true,
            showHeader: true,
            condition: 'true'
        },
    ];
    public currentStep: any = this.steps[0]
    public key = 1


    isHeaderShown(step: any) {
        return step.enable && step.showHeader && eval(step.condition as string)
    }

    setStep(step: any) {
        this.currentStep = step
        this.save()
    }

    public isBranchSelected(code: string) {
        if (!this.callOffer.useForm) {
            return false
        }
        const b = this.callOffer.branches.find((b) => {
            return b.code === code
        })
        if (b) {
            return b.selected
        }
        return false
    }

    enableStep(name: string) {
        for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].title === name) {
                this.steps[i].enable = true
            }
        }
    }

    disableStep(name: string) {
        for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].title === 'name') {
                this.steps[i].enable = false
            }
        }
    }

    async mounted() {
        await this.getEnterpriseInfo()
        // this.load(this.$route.params.uuid);
    }


    async load(uuid: string) {
        const res = await api.get('selfcare/call/offer/get/' + uuid)
        if (res && res.data && res.data.callOffer) {
            this.callOffer = new CallOffer(res.data.callOffer)
            this.legacy = new CallOffer(res.data.callOffer)
            this.key++
        }
    }

    async save() {
        let popup = new Popup('', 'Sauvegarde en cours', 'warning', 'fad fa-save', true)
        // if (JSON.stringify(this.callOffer) !== JSON.stringify(this.legacy)) {
        const callOffer = this.callOffer
        const res = await api.post('selfcare/call/offer/edit', {callOffer});
        if (res && res.data && res.data.callOffer) {
            this.callOffer = res.data.callOffer
            this.$router.push({name: 'call-offer-form', params: {uuid: res.data.callOffer.uuid}})
        }
        popup.hide()
        // }else{
        //     console.log('no change')
        // }
    }

    next() {
        this.save()
        for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].id > this.currentStep.id && this.steps[i].enable && eval(this.steps[i].condition as string)) {
                this.currentStep = this.steps[i]
                console.log(this.currentStep)
                return;
            }
        }
    }

    previous() {
        console.log('previous')
        for (let i = (this.steps.length - 1); i >= 0; i--) {
            const step = this.steps[i];
            if (step.id < this.currentStep.id && step.enable && eval(step.condition as string)) {
                this.currentStep = step;
                return;
            }
        }
    }

    customer: Customer | null = null
    enterprise: Enterprise = new Enterprise()
    isIdentified = false

    async getEnterpriseInfo() {
        this.$store.commit('loading')
        const res = await api.get('selfcare/customer/infos')
        if (res && res.data && res.data.customer) {
            this.$store.state.user = new Customer(res.data.customer);
        }
        this.customer = this.$store.state.user as Customer;
        if (this.customer.enterprise) {
            this.enterprise = this.customer.enterprise
        } else {
            this.enterprise = new Enterprise()
            this.customer.enterprise = this.enterprise
        }
        if (this.identify()) {
            this.isIdentified = true
        }
        this.$store.commit('stopLoading')
    }

    identify() {
        return this.customer instanceof Customer && this.enterprise instanceof Enterprise
            && this.customer.isIdentified() && this.enterprise.isIdentified()
    }

}
