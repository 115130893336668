





import {Component, Vue} from 'vue-property-decorator';
import {auth} from "@/services/Auth";
import {api} from "@/services/Api";

@Component({})
export default class TokenAuth extends Vue {
    [x: string]: any;

    async mounted() {
        // const token: any = this.$route.query.t
        const refresh: any = this.$route.query.r
        if (refresh) {
            // this.$store.state.userToken = token
            this.$store.state.userRefreshToken = refresh
            localStorage.setItem('refreshToken', refresh)
            // localStorage.setItem('userToken', token)
            await api.refreshToken()
            this.$router.push({name : 'home'})
        }


    }

}
