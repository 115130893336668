
































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CallOffer from "@/entity/CallOffer";
import FileUpload from "@/components/FileUpload.vue";
import File from "@/entity/File";

@Component({
    components: {FileUpload}
})
export default class CallOfferCarInsurance extends Vue {
    @Prop() callOffer!: CallOffer

    updateCarList(uuid: string) {
        const file = new File();
        file.uuid = uuid;
        this.callOffer.data.auto.list = file as any
        this.$emit('save')
    }
    mounted(){
        // console.log(this.callOffer.data.auto.list)
    }

    next() {
        this.$emit('next')
    }
    save() {
        this.$emit('save')
    }


    previous() {
        this.$emit('previous')
    }
}
