









































































import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import Popup from "@/entity/Popup";

@Component({})
export default class ForgotPassword extends Vue {
    [x: string]: any;

    loading = false
    email = ''

    async resetPassword() {
        if (this.loading) {
            return
        }
        this.loading = true
        const res = await api.get('shared/customer/reset/password/' + this.email)
        if(res && res.data && res.data.status === 'success'){
            new Popup('Email Envoyé',
                this.trans('Si le compte "<b>' + this.email + '</b>" existe chez WiASSUR un email contenant un lien y sera envoyé.')
                + this.trans(' Cliquer sur le lien pour réinitialiser votre mot de passe.')
                + '<br> <span style="color:indianred    ;font-weight: bolder">' +
                this.trans('Si vous ne l\'avez pas reçu, pensez à vérifier vos spams')
                + '</span>'
                ,'success','fad fa-thumbs-up',true);
        }
        // console.log(res)
        this.loading = false
    }
}
