<template>
    <div id="app">
<!--        <CountrySelector></CountrySelector>-->
        <router-view/>
        <Popup></Popup>
        <Loading v-if="$store.state.isLoading"></Loading>
    </div>
</template>

<style>

    :root {
        --primary: #3DDAD7;
        --warning: #FFD160;
        --danger: #F8069C;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;
    }

    #app {
        flex: 1;
        display: flex;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }
</style>
<script>
    import {Component, Vue} from 'vue-property-decorator';
    import Popup from "./components/Popup";
    import Loading from "./components/Loading";
    import CountrySelector from "./components/CountrySelector";

    @Component({
        components: {CountrySelector, Loading, Popup}
    })
    export default class App extends Vue {

    }
</script>
