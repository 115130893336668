/* eslint-disable */
import UserExtend from "@/entity/extends/UserExtend";

export default class User extends UserExtend {

public id!: number;
public email = '';
public roles= [];
public password = '';
// public contractsManaged: Array<Contract> = [];

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.email= object.email;
       this.roles= object.roles;
       this.password= object.password;
       // if(object.contractsManaged){
       //     object.contractsManaged.forEach((occ: any)=>{
       //         const contract= new Contract(occ);
       //         this.contractsManaged.push(contract);
       //     });
       // }
      }
  }

}
