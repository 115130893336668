





























































import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import CallOffer from "@/entity/CallOffer";
import {api} from "@/services/Api";

@Component({
    components: {Base}
})
export default class CallOfferEdit extends Vue {
    public callOffer: any = null

    async beforeMount() {
        await this.load(this.$route.params.uuid);
    }

    mounted() {
        // this.load(this.$route.params.uuid);
    }


    async load(uuid: string) {
        const res = await api.get('selfcare/call/offer/get/' + uuid)
        if (res && res.data && res.data.callOffer) {
            this.callOffer = new CallOffer(res.data.callOffer)
            // this.legacy = new CallOffer(res.data.callOffer)
            // this.key++
        }
    }
}
