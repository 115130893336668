



















































































































































import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Customer from "@/entity/Customer";

@Component({
    components: {Base}
})
export default class Contact extends Vue {
    public tab = 1
    public subjects = [
        'Comparator', 'Subscription', 'Contract', 'Selfcare',
        'Recommandation', 'General_question', 'WiASSUR'];
    public data = {
        subject: null,
        message: null,
        'source': 'selfcare'
    }
    public rdv = {
        subject: null,
        date: null,
        message: null,
        phone: null,
        email: null,
        'source': 'selfcare'
    }

    async save() {
        console.log(this.data)
    }

    async saveRdv() {
        const res = await api.post('selfcare/customer/take/appointment', {rdv: this.rdv});
        console.log(res)
    }

    async mounted() {
        this.$store.commit('loading')
        await this.getInfos()
        this.$store.commit('stopLoading')
    }

    async getInfos() {
        const res = await api.get('selfcare/customer/infos')
        if (res && res.data && res.data.customer) {
            this.$store.state.user = new Customer(res.data.customer);
        }
        const customer = this.$store.state.user as Customer;
        if (customer.hasContact()) {
            this.rdv.phone = customer.phones[0].number as any
        }
        this.rdv.email = customer.email as any
    }
}
