


































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CallOffer from "@/entity/CallOffer";
import FileUpload from "@/components/FileUpload.vue";
import File from "@/entity/File";
import RcProOnlineForm from "@/views/enterprise/CallOffer/Query/Steps/RcProOnlineForm.vue";

@Component({
    components: {RcProOnlineForm, FileUpload}
})
export default class CallOfferRcPro extends Vue {
    @Prop() callOffer!: CallOffer

    updateForm(uuid: string) {
        const file = new File();
        file.uuid = uuid;
        this.callOffer.data.rcPro.form = file as any
        this.$emit('save')
    }

    async enableForm() {
        this.$emit('enableStep', 'rcProOnlineForm')
    }

    disableForm() {
        this.$emit('disableStep', 'rcProOnlineForm')
    }

    async goToForm() {
        this.callOffer.data.rcPro.online = true
        this.$emit('save')
        // await this.enableForm()
        // this.next()
    }

    save() {
        this.$emit('save')
    }

    next() {
        this.$emit('next')
    }

    previous() {
        this.$emit('previous')
    }
}
