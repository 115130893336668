









import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import EnterpriseInfo from "@/views/enterprise/CallOffer/EnterpriseInfo.vue";
import Customer from "@/entity/Customer";
import {api} from "@/services/Api";
import Enterprise from "@/entity/Enterprise";
import Popup from "@/entity/Popup";

@Component({
    components: {EnterpriseInfo, Base}
})
export default class CallOfferParameter extends Vue {
    [x: string]: any;
    customer: Customer | null = null
    enterprise: Enterprise = new Enterprise()
    isIdentified = false

    updateCustomer(customer: any) {
        // console.log(customer);
        this.$store.state.user = new Customer(customer);
        this.customer = this.$store.state.user as Customer;
        if (this.customer.enterprise) {
            this.enterprise = this.customer.enterprise;
        }
        // console.log(this.customer)
        // console.log(this.enterprise)
        if (this.identify()) {
            new Popup(this.trans('Congratulations'), this.trans('Your_informations_have_been_updated'), 'success', 'fad fa-thumbs-up');
        }
    }

    identify() {
        return this.customer instanceof Customer && this.enterprise instanceof Enterprise
            && this.customer.isIdentified() && this.enterprise.isIdentified()
    }
    async getEnterpriseInfo() {
        const res = await api.get('selfcare/customer/infos')
        if (res && res.data && res.data.customer) {
            this.$store.state.user = new Customer(res.data.customer);
        }
        this.customer = this.$store.state.user as Customer;
        if (this.customer.enterprise) {
            this.enterprise = this.customer.enterprise
        } else {
            this.enterprise = new Enterprise()
            this.customer.enterprise = this.enterprise
        }
    }

    async mounted() {
        this.$store.commit('loading');
        await this.getEnterpriseInfo()
        this.$store.commit('stopLoading');
    }

}
