







































































































































































































































































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CallOffer from "@/entity/CallOffer";

@Component({})
export default class RcProOnlineForm extends Vue {
    @Prop() callOffer!: CallOffer


    toggleForm() {
        this.callOffer.data.rcPro.online = false
        this.$emit('save')
    }

    next() {
        this.$emit('next')
        // console.log(JSON.parse(JSON.stringify(this.callOffer.data.mrp.onlineForm)))
    }

    previous() {
        this.$emit('previous')
    }
}
