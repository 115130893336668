import Vue from 'vue'
import Vuex from 'vuex'
import Popup from "@/entity/Popup";
import Customer from "@/entity/Customer";
import {api} from "@/services/Api";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoading: false,
        user: localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined' && localStorage.getItem('user') !== 'null' ? new Customer(JSON.parse(localStorage.getItem('user')!)) : null,
        userToken: localStorage.getItem('userToken') || null,
        userRefreshToken: localStorage.getItem('userRefreshToken') || null,
        platform: localStorage.getItem('platform') || 'offers',
        popups: [],
        backend: process.env.VUE_APP_BACKEND_URL,
        selfcare: process.env.VUE_APP_SELFCARE_URL,
        comparator: process.env.VUE_APP_COMPARATOR_URL,
        lang: localStorage.getItem('lang') || 'fr',
        sleep: (ms: number) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    },
    mutations: {
        removePopup(state: any, id) {
            const index = state.popups.findIndex((popup: Popup) => {
                return popup.id === id;
            });
            if (index !== -1) {
                state.popups.splice(index, 1);
            }
        },
        loading(state: any) {
            state.isLoading = true;
        },
        stopLoading(state: any) {
            state.isLoading = false;
        },
        logout(state: any) {
            localStorage.removeItem('userToken');
            localStorage.removeItem('userRefreshToken');
            localStorage.removeItem('token');
            state.user = null;
            state.userToken = null;
        },
    },
    actions: {},
    modules: {},
    getters: {
        getCustomer: async (state, getters) => {
            if (state.user) {
                return state.user
            } else {
                const res = await api.get('selfcare/customer/infos')
                if(res && res.data && res.data.customer){
                    state.user = new Customer(res.data.customer)
                    localStorage.setItem('user',JSON.stringify(res.data.customer))
                }

            }

        }
    }
})
