import fr from "../assets/lang/fr.json";
import en from "../assets/lang/en.json";
import {api} from "@/services/Api";
import store from "@/store";


class Translator {
    dico: any = {};
    public unknows: string[] = []
    public to: any = null
    public loaded = false
    public delay = 2000

    constructor() {
        this.dico = {fr, en}
    }

    init() {
//
    }

    translate(code: string) {
        if(!code || code.trim() === ''){
            return '';
        }
        const result = code.replace(/_/g, ' ');
        const dico = this.dico[store.state.lang]
        if (!Object.prototype.hasOwnProperty.call(dico, code)) {
            Object.assign(dico, {[code]: result});
            clearTimeout(this.to)
            this.to = null
            this.unknows.push(code)
            // const now = new Date()
            // console.log('unknow ' + code + ' ' + now.getHours() + 'h' + now.getMinutes()+'m'+now.getSeconds())
            if (this.loaded) {
                this.to = setTimeout(() => {
                    this.saveUnknow()
                }, this.delay)
            }
            return result;
        } else {
            return dico[code];
        }
    }

    async saveUnknow() {
        clearTimeout(this.to)
        this.to = null
        // console.log(this)
        if (this.unknows.length) {
            const res = await api.post( 'shared/save/unknow/translations', {
                codes: this.unknows,
                'source' : 'selfcare'
            });
            this.unknows = []
        }
    }
}

export const trans = new Translator();
window.addEventListener('load', () => {
    // console.log('load')
    clearTimeout(trans.to)
    trans.to = null
    trans.loaded = true
    trans.to = setTimeout(() => {
        trans.saveUnknow()
    }, trans.delay)
})
