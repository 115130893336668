























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
Pagination extends Vue {
    @Prop() count!: number;
    @Prop() matchCount!: number;
    @Prop() itemLength!: number;
    @Prop() page!: number;
    @Prop() perPage!: number;

    setPage(num: number): void {
        // this.$store.state.filter.page = num;
        this.$store.commit('saveFilter');
        this.$emit('load');
    }


    get from(): number {
        return ((this.page - 1) * this.perPage) + 1;
    }

    get maxPage(): number {
        const max = this.matchCount > 0 ? Math.ceil(this.matchCount / this.perPage) : 1;
        if (this.page > max) {
            this.page = 1;
            // this.$store.commit('saveFilter');
            this.$emit('load');
        }
        return max;
    }

    get to(): number {
        return this.from + this.itemLength - 1;
    }
}
