






import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";

@Component({
    components: {Base}
})
export default class DevisNew extends Vue {
}
