









































































































































/* eslint-disable */
import {Component, Prop, Vue} from 'vue-property-decorator';
import CallOffer from "@/entity/CallOffer";
import FileUpload from "@/components/FileUpload.vue";
import File from "@/entity/File";

@Component({
    components: {FileUpload}
})
export default class CallOfferSante extends Vue {
    @Prop() callOffer!: CallOffer
    college = {
        name: '',
        nombre: 0,
        taux: '80%',
        reseau: 'Economique',
        etendue: ''
    }

    mounted() {
        if (!this.callOffer.data.sante) {
            const d = new CallOffer()
            this.callOffer.data.sante = d.data.sante
        }
        if (!this.callOffer.data.sante.colleges) {
            const d = new CallOffer()
            this.callOffer.data.sante.colleges = []
        }
        if (this.callOffer.data.sante.colleges.length === 0) {
            this.addCollege()
        }
        // console.log(this.callOffer.data.sante.colleges)
    }




    addCollege() {
        let col = JSON.parse(JSON.stringify(this.college))
        col.name = 'Collège ' + (this.callOffer.data.sante.colleges.length + 1)
        this.callOffer.data.sante.colleges.push(col)
    }

    removeCollege(college: any) {
        const index = this.callOffer.data.sante.colleges.findIndex((c) => {
            return c === college
        })
        if (index !== -1) {
            this.callOffer.data.sante.colleges.splice(index, 1)
            this.save()
        }
    }

    updateList(uuid: string) {
        const file = new File();
        file.uuid = uuid;
        this.callOffer.data.sante.list = file as any
        this.$emit('save')
    }
    save(){
        this.$emit('save')
    }

    next() {
        this.$emit('next')
    }

    previous() {
        this.$emit('previous')
    }
}
