














































































































































import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import CallOffer from "@/entity/CallOffer";
import {api} from "@/services/Api";

@Component({
    components: {Base}
})
export default class CallOfferResponse extends Vue {

    public callOffer: any = null
    isBrancheToggle = false

    async beforeMount() {
        // await this.load(this.$route.params.uuid);
    }

    async mounted() {
        await this.load(this.$route.params.uuid);
        let isBranches = true
        // let isInsurers = true
        this.callOffer.branches.forEach((b: any) => {
            if (!b.selected) {
                isBranches = false
            }
        })
        // console.log(isBranches)
        if (isBranches) {
            this.isBrancheToggle = true
        }

        // this.callOffer.insurers.forEach((i) => {
        //     if (!i.selected) {
        //         isInsurers = false
        //     }
        // })
        // if (isInsurers) {
        //     this.isInsurerToggle = true
        // }
    }

    toggleBranches() {
        let isAllChecked = true
        this.callOffer.branches.forEach((b: any) => {
            if (!b.selected) {
                isAllChecked = false
            }
        })
        if (!isAllChecked) {
            this.callOffer.branches.forEach((b: any) => {
                b.selected = true
            })
        } else {
            this.callOffer.branches.forEach((b: any) => {
                b.selected = false
            })
        }
    }


    async load(uuid: string) {
        const res = await api.get('selfcare/call/offer/get/' + uuid)
        if (res && res.data && res.data.callOffer) {
            this.callOffer = new CallOffer(res.data.callOffer)
            // this.legacy = new CallOffer(res.data.callOffer)
            // this.key++
        }
    }
}
