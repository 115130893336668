























































































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CallOffer from "@/entity/CallOffer";

@Component({})
export default class MultirisqueOnlineForm extends Vue {

    @Prop() callOffer!: CallOffer
    guarantees = [
        {name: 'Incendie, explosion et évènements assimilés', selected: false, capital: 0},
        {name: 'Accidents aux appareils électriques', selected: false, capital: 0},
        {name: 'Dégât des eaux', selected: false, capital: 0},
        {name: 'Bris de glace', selected: false, capital: 0},
        {name: 'Tous risques informatiques', selected: false, capital: 0},
        {name: 'Marchandises en chambre froide', selected: false, capital: 0},

        {name: 'Vol dans les locaux', selected: false, capital: 0},
        {name: 'Vol sur la personne, perte par cas de force majeure', selected: false, capital: 0},
        {name: 'Vol Détournements', selected: false, capital: 0},
        {name: 'Perte d’exploitation suite bris de machine', selected: false, capital: 0},
        {
            name: 'Grèves, Emeutes, Mouvements populaires, Actes de terrorisme et Sabotage',
            selected: false,
            capital: 0
        },
        {name: 'Tempête, Ouragan et Cyclone', selected: false, capital: 0},

        {name: 'Tremblement de terre, Eruption volcanique', selected: false, capital: 0},
        {name: 'Perte d’exploitation suite bris de machine', selected: false, capital: 0},
        {name: 'Hautes eaux Inondations', selected: false, capital: 0},
        {name: 'Bris de machine', selected: false, capital: 0},
        {name: 'Responsabilité civile chef d’entreprise', selected: false, capital: 0},
    ]

    toggleForm() {
        this.callOffer.data.mrp.online = false
        this.$emit('save')
    }

    mounted() {
        if (this.callOffer.data.mrp.onlineForm.garanties === null) {
            this.callOffer.data.mrp.onlineForm.garanties = this.guarantees as any
        }
    }

    next() {
        this.$emit('next')
        // console.log(JSON.parse(JSON.stringify(this.callOffer.data.mrp.onlineForm)))
    }

    previous() {
        this.$emit('previous')
    }
}
