





































































import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Customer from "@/entity/Customer";
import Enterprise from "@/entity/Enterprise";
import EnterpriseInfo from "@/views/enterprise/CallOffer/EnterpriseInfo.vue";
import Popup from "@/entity/Popup";
import CallOffer from "@/entity/CallOffer";

@Component({
    components: {EnterpriseInfo, Base}
})
export default class CallOfferHome extends Vue {
    [x: string]: any;

    customer: Customer | null = null
    enterprise: Enterprise = new Enterprise()
    isIdentified = false
    callOffers: CallOffer[] = []

    updateCustomer(customer: any) {
        // console.log(customer);
        this.$store.state.user = new Customer(customer);
        this.customer = this.$store.state.user as Customer;
        if (this.customer.enterprise) {
            this.enterprise = this.customer.enterprise;
        }
        if (this.identify()) {
            this.isIdentified = true
            new Popup(this.trans('Congratulations'), this.trans('Your_informations_have_been_updated'), 'success', 'fad fa-thumbs-up');
        }
    }

    async getEnterpriseInfo() {
        const res = await api.get('selfcare/customer/infos')
        if (res && res.data && res.data.customer) {
            this.$store.state.user = new Customer(res.data.customer);
        }
        this.customer = this.$store.state.user as Customer;
        if (this.customer.enterprise) {
            this.enterprise = this.customer.enterprise
        } else {
            this.enterprise = new Enterprise()
            this.customer.enterprise = this.enterprise
        }
        if (this.identify()) {
            this.isIdentified = true
        }
    }

    identify() {
        return this.customer instanceof Customer && this.enterprise instanceof Enterprise
            && this.customer.isIdentified() && this.enterprise.isIdentified()
    }

    async getList() {
        const res = await api.get('selfcare/call/offer/list');
        if (res && res.data && res.data.callOffers) {
            this.callOffers = []
            res.data.callOffers.forEach((c: CallOffer) => {
                this.callOffers.push(new CallOffer(c))
            })
        }
    }

    async createCallOffer() {
        const callOffer = new CallOffer()
        const res = await api.post('selfcare/call/offer/edit', {callOffer});
        if (res && res.data && res.data.callOffer) {
            this.$router.push({name: 'call-offer-edit', params: {uuid: res.data.callOffer.uuid}})
        }
    }


    async mounted() {
        this.$store.commit('loading');
        await this.getEnterpriseInfo()
        await this.getList()
        if (this.identify()) {
            this.isIdentified = true
        }
        this.$store.commit('stopLoading');
    }

}
