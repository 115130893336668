import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {auth} from "@/services/Auth";
import {trans} from "@/services/Translator";
import {helper} from "@/services/Helper";

import BootstrapVue from "bootstrap-vue"
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/style.bundle.css'
import {actions} from "@/Utils/Actions";
// @ts-ignore
import Paginate from 'vuejs-paginate';


Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.component('paginate', Paginate);

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.requiredRoles) {
        if (auth.hasRoles(to.meta.requiredRoles)) {
            next();
            return;
        } else {
            console.log('has no role')
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            });
        }
    }
    next();
});
const getCircularReplacer = () => {
    const seen = new WeakSet();
    const disallowed = ['createdAt', 'updatedAt', 'createBy', 'updateBy', 'removeBy', 'createdFromIp', 'updatedFromIp'];
    return (key: any, value: any) => {
        if (disallowed.includes(key)) {
            return
        }
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};
Vue.prototype.stringify = (obj: any) => {
    return JSON.stringify(obj, getCircularReplacer());
}
Vue.prototype.trans = (code: string) => {
    return trans.translate(code)
}
Vue.prototype.empty = (data: string | undefined): boolean => {
    return data === null || data === undefined || data.length === 0
}
Vue.prototype.helper = helper
Vue.prototype.actions = actions
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
