/* eslint-disabled */

import Phone from "@/entity/Phone";
import Popup from "@/entity/Popup";
import CallOffer from "@/entity/CallOffer";

export default class CustomerExtend {
    public name? = '';
    public prename? = '';
    public email = '';
    public civility? = '';
    public fonction? = '';
    public phones: Array<Phone> = [];
    public mobile = ''
    public fixe = ''
    public callOffers: Array<CallOffer> = [];

    constructor(object?: any) {
        this.phones.push(new Phone())
        this.phones.push(new Phone())
    }

    isIdentified() {
        return !this.empty(this.civility) && !this.empty(this.name) && !this.empty(this.prename) && !this.empty(this.fonction) && !this.empty('mobile')
    }

    validate() {
        if (this.empty(this.civility)) {
            new Popup('Information manquante', 'Veuillez renseigner la civilité')
            return false;
        }
        if (this.empty(this.name)) {
            new Popup('Information manquante', 'Veuillez renseigner le nom')
            return false;
        }
        if (this.empty(this.prename)) {
            new Popup('Information manquante', 'Veuillez renseigner le prénom')
            return false;
        }
        if (this.empty(this.fonction)) {
            new Popup('Information manquante', 'Veuillez renseigner la fonction')
            return false;
        }
        if (this.phones.length === 0) {
            new Popup('Information manquante', 'Veuillez renseigner au moins un contact')
            return false;
        }
        if (this.empty(this.mobile)) {
            new Popup('Information manquante', 'Veuillez renseigner le numéro mobile')
            return false;
        }

        return true
    }

    get identifier() {
        let identifier = this.prename + ' ' + this.name;
        if (identifier.trim() === '') {
            identifier = this.email
        }
        if (identifier === 'null null') {
            identifier = ''
        }
        return identifier
    }

    empty(data: string | undefined): boolean {
        return data === null || data === undefined || data.length === 0
    }

    hasContact() {
        let empty = true
        this.phones.forEach((p) => {
            if (!this.empty(p.number)) {
                empty = false
            }
        })
        return !empty
    }

    hasEmail() {
        return !this.empty(this.email)
    }

    getCallOfferStatusCount(type: string) {
        let count = 0
        this.callOffers.forEach((c: CallOffer) => {
            if (c.status === type) {
                count++
            }
        })
        return count;
    }
}
