import {RouteConfig} from "vue-router";
import DevisHome from "@/views/enterprise/Devis/DevisHome.vue";
import DevisNew from "@/views/enterprise/Devis/DevisNew.vue";


const prefix = '/devis'
const devisRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'devis-index',
        component: DevisHome,
        meta: {requiredRoles: ['ROLE_CUSTOMER']},
    },
    {
        path: '/new',
        name: 'devis-new',
        component: DevisNew,
        meta: {requiredRoles: ['ROLE_CUSTOMER']},
    },
]
devisRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default devisRoutes;
