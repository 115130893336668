




























































































































































import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import CallOffer from "@/entity/CallOffer";
import {api} from "@/services/Api";
import Pagination from "@/components/Pagination.vue";
import Loading from "@/components/Loading.vue";

@Component({
    components: {Loading, Pagination, Base}
})
export default class CallOfferList extends Vue {

    callOffers: CallOffer[] = []
    matchCount = 0
    loading = false
    count = 0
    filter = {
        page: 1,
        perPage: 50,
        branch: null,
        dateIn: null,
        dateOut: null,
        timeIn: null,
        timeOut: null,
        contain: null,
        hasResponseTo: null,
    }

    load() {

    }

    async mounted() {
        this.getList()
    }

    async getList() {
        this.loading = true
        const res = await api.get('selfcare/call/offer/full/list');
        if (res && res.data && res.data.callOffers) {
            res.data.callOffers.forEach((callOffer: CallOffer) => {
                this.callOffers.push(new CallOffer(callOffer))
            })
        }
        this.loading = false
    }
}
