





















































































import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";

@Component({})
export default class RightMenu extends Vue {
    public notificationTabIndex = 0;
    public person = false;

    logout(): void {
        api.logout();
    }

    mounted(): void {
        // console.log(this.$store.state.user)
        if(!this.$store.state.user){
            this.$store.getters.getCustomer()
        }
        // this.user = this.$store.getters.getUser();
        // if (this.user && localStorage.person) {
        //     this.person = true;
        //     this.user = JSON.parse(localStorage.person);
        // }
        // this.load()

        // console.log(KTMenu);
        // KTMenu.init();
    }

    async load(): Promise<void> {
        // const res = await api.get('api/subscription/list');
        // res.subscriptions.forEach((item: Subscription) => {
        //     this.subscriptions.push(new Subscription(item));
        // })
    }
}
