<template>
  <Base>
    <template v-slot:content>
      HOME
    </template>
  </Base>
</template>

<script>
// @ is an alias to /src
import Base from "../layouts/Base";

export default {
  name: 'Home',
  components: {
    Base,
  }
}
</script>
