




































































































































































import {Component, Vue} from 'vue-property-decorator';
import {auth} from "@/services/Auth";
import ComparatorMenu from "@/layouts/Menus/ComparatorMenu.vue";

@Component({
    components: {ComparatorMenu}
})
export default class LeftAside extends Vue {
    public route = 'dashboard';
    public selected = '';
    public minimized = 'on';
    public animating = false;

    // public isAdmin = true;

    isAdmin(): boolean {
        return auth.isAdmin();
    }

    hasRoles(roles: string[]) {
        return auth.hasRoles(roles)
    }

    toggle(): void {

        const state = document.body.getAttribute('data-kt-aside-minimize');
        if (state === 'on') {
            document.body.setAttribute('data-kt-aside-minimize', 'off');
        } else {
            this.animating = true;
            document.body.setAttribute('data-kt-aside-minimize', 'on');
            const t = setTimeout(() => {
                this.animating = false;
                clearTimeout(t);
            }, 500);
        }
        const newState = document.body.getAttribute('data-kt-aside-minimize');
        if (newState) {
            localStorage.setItem('navState', newState);
        }
        this.$forceUpdate();
    }

    isRouted(array: Array<any>) {
        // console.log(array)
        // console.log(this.route)
        // console.log(array.includes(this.route))
        return array.includes(this.route)
    }

    isMinimized(): boolean {
        return this.minimized === 'on';
    }

    isActive(name: string): boolean {
        return this.route.trim().length > 0 && this.route.includes(name);
    }

    isOpen(name: string): boolean {
        return this.selected.trim().length > 0 && this.selected.includes(name);
    }


    isExactRoute(name: string): boolean {
        return name === this.route;
    }

    select(name: string): void {
        if (this.selected && this.selected.includes(name)) {
            this.selected = '';
        } else {
            this.selected = name;
        }
    }

    mounted(): void {

        if (this.$router.currentRoute.name) {
            this.route = this.$router.currentRoute.name;
            this.selected = this.$router.currentRoute.name;
        }
        // console.log(this.route)

        // if (localStorage.getItem('navState')) {
        //     const navState = localStorage.getItem('navState');
        //     if (navState !== null) {
        //         this.minimized = navState;
        //         if (this.minimized === 'on') {
        //             document.body.setAttribute('data-kt-aside-minimize', 'on');
        //         } else {
        //             document.body.setAttribute('data-kt-aside-minimize', 'off');
        //         }
        //     }
        // }

    }
}
