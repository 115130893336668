/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import store from "@/store";
import {api} from "@/services/Api";
import router from "@/router";
import Customer from "@/entity/Customer";

class Auth {

    ROLE_HIERARCHY: any = {
        ROLE_ADMIN: ['ROLE_USER','ROLE_COMMUNICATION','ROLE_SELL','ROLE_PRODUCTION','ROLE_INCOME','ROLE_MANAGER','ROLE_ENTERPRISE_MANAGER',
        'ROLE_CUSTOMER']
    }

    async login(username: string, password: string) {
        const result = await api.post('selfcare/login_check', {username: username, password: password});
        if (result && result.data && result.data.token) {
            const data = result.data;
            store.state.userToken = data.token
            store.state.userRefreshToken = data.refreshToken
            localStorage.setItem('mercureToken', data.mercure)
            localStorage.setItem('refreshToken', data.refreshToken)
            localStorage.setItem('userToken', data.token)
            localStorage.setItem('user', JSON.stringify(data.customer))
            store.state.user = new Customer(data.customer);
            // console.log(this.isActive())
            this.redirectHome()
        }
        return result;
    }

    redirectHome() {
        switch (store.state.platform) {
            case 'offers': {
                if (router.currentRoute.name !== 'call-offer-index') {
                    router.push({name: 'call-offer-index'});
                    break;
                }
            }
            default: {
                router.push({name: 'home'})
            }
        }
    }

    loadData() {
        const token = localStorage.getItem('userToken') || '';
        const refreshToken = localStorage.getItem('refreshToken') || '';
        store.state.userToken = token
        store.state.userRefreshToken = refreshToken
        return {token, refreshToken};
    }

    isConnected(): any {
        const data = this.loadData();
        if (!data) {
            return false;
        }
        return data.token;
    }

    isActive() {
        const data = this.loadData();
        if (!data) {
            return false;
        }
        const payload = this.parseJwt(data.token);
        return payload.active
    }

    isAdmin(): boolean {
        const data = this.loadData();
        if (!data) {
            return false;
        }
        const payload = this.parseJwt(data.token);
        if (payload.hasOwnProperty('roles')) {
            return payload.roles.includes('ROLE_ADMIN');
        }
        // new Popup('Erreur', 'L\'authentification a échoué', 'danger', 'fad fa-user');
        this.logout();
        return false;
    }

    logout(): void {
        // store.commit('logout');
        // localStorage.removeItem('user');
        // localStorage.removeItem('userRefreshToken');
        // localStorage.removeItem('token');
        // if (router.currentRoute.name !== 'login') {
        //     router.push({name: 'login'});
        // }
    }

    hasRoles(roles: string[]) {
        const {token} = this.loadData()
        // console.log('arzar')
        const data = this.parseJwt(token)
        // console.log(data)
        let userRoles: any[] = []
        data.roles.forEach((role: any) => {
            if (this.ROLE_HIERARCHY.hasOwnProperty(role)) {
                userRoles = userRoles.concat(this.ROLE_HIERARCHY[role])
            }
            if (!userRoles.includes(role)) {
                userRoles.push(role)
            }

        })
        let fullfit = true
        roles.forEach((role) => {
            if (!userRoles.includes(role)) {
                fullfit = false
                console.log('user has no role ' + role)
            }
        })
        return fullfit
    }

    parseJwt(token: string): any {
        if (!token) {
            // console.log(token + ' token is empty');
            return {roles: []};
        }
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}

export const auth = new Auth();
