import {RouteConfig} from "vue-router";
import CallOfferList from "@/views/admin/CallOfferList.vue";
import adminIndex from "@/views/admin/adminIndex.vue";
import Customerlist from "@/views/admin/Customerlist.vue";


const prefix = '/admin'
const adminRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'admin-index',
        component: adminIndex,
        meta: {requiredRoles: ['ROLE_ADMIN']},
    },
    {
        path: '/user/list',
        name: 'user-list',
        component: Customerlist,
        meta: {requiredRoles: ['ROLE_ADMIN']},
    },
    {
        path: '/call/offer/list',
        name: 'call-offer-list',
        component: CallOfferList,
        meta: {requiredRoles: ['ROLE_ADMIN']},
    },
]
adminRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default adminRoutes;
