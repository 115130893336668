/* eslint-disabled */
import Enterprise from "./Enterprise";
import Phone from "./Phone";
import Appointment from "./Appointment";
import CallOffer from "./CallOffer";
import User from "./User";
import CustomerExtend from "@/entity/extends/CustomerExtend";

export default class Customer extends CustomerExtend {

    public id!: number;
    public name? = '';
    public prename? = '';
    public email = '';
    public password? = '';
    public roles= [];
    public salt? = '';
    public active!: boolean;
    public employer?: Enterprise;
    public civility? = '';
    public fonction? = '';
    public phones: Array<Phone> = [];
    public enterprise?: Enterprise;
    public appointments: Array<Appointment> = [];
    public callOffers: Array<CallOffer> = [];
    public mobile = '';
    public fixe = '';
    public uuid = '';
    public createdAt?: Date;
    public updatedAt?: Date;
    public createBy?: User;
    public updateBy?: User;
    public removeBy?: User;
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public deletedAt?: Date;

    constructor (object?: any) {
        super(object)
        if(object){
            this.id= object.id;
            this.name= object.name;
            this.prename= object.prename;
            this.email= object.email;
            this.password= object.password;
            this.roles= object.roles;
            this.salt= object.salt;
            this.active= object.active;
            this.employer = (object.employer instanceof Enterprise) ? object.employer : new Enterprise(object.employer);
            this.civility= object.civility;
            this.fonction= object.fonction;
            if(object.phones){
                object.phones.forEach((occ: any)=>{
                    const phone= new Phone(occ);
                    this.phones.push(phone);
                });
            }
            this.enterprise = (object.enterprise instanceof Enterprise) ? object.enterprise : new Enterprise(object.enterprise);
            if(object.appointments){
                object.appointments.forEach((occ: any)=>{
                    const appointment= new Appointment(occ);
                    this.appointments.push(appointment);
                });
            }
            if(object.callOffers){
                object.callOffers.forEach((occ: any)=>{
                    const calloffer= new CallOffer(occ);
                    this.callOffers.push(calloffer);
                });
            }
            this.mobile= object.mobile;
            this.fixe= object.fixe;
            this.uuid= object.uuid;
            if(object.createdAt){
                this.createdAt= new Date(object.createdAt);
            }
            if(object.updatedAt){
                this.updatedAt= new Date(object.updatedAt);
            }
            this.createBy = (object.createBy instanceof User) ? object.createBy : new User(object.createBy);
            this.updateBy = (object.updateBy instanceof User) ? object.updateBy : new User(object.updateBy);
            this.removeBy = (object.removeBy instanceof User) ? object.removeBy : new User(object.removeBy);
            this.createdFromIp= object.createdFromIp;
            this.updatedFromIp= object.updatedFromIp;
            if(object.deletedAt){
                this.deletedAt= new Date(object.deletedAt);
            }
        }
    }

}
