































import {Component, Vue} from 'vue-property-decorator';


import HeaderTop from "@/layouts/HeaderTop.vue";
import {auth} from "@/services/Auth";
import Toolbar from "@/layouts/Toolbar.vue";
import LeftAside from "@/layouts/LeftAside.vue";


@Component({
    components: {LeftAside, Toolbar, HeaderTop}
})
export default class Base extends Vue {
    public fixedToolbar = true;
    public toolbarEnabled = true;

    get isConnected(): boolean {
        return auth.isConnected();
    }


    mounted(): void {

        //     console.log(this.$route);
    }
}
