









































import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";

@Component({})
export default class LeftMenu extends Vue {
    public website = process.env.VUE_APP_BACKEND_URL;

    contracts() {
        localStorage.setItem('platform', 'contracts')
        this.$store.state.platform = 'contracts'
        this.$router.push({name: 'contract-index'});
    }

    offers() {
        localStorage.setItem('platform', 'offers')
        this.$store.state.platform = 'offers'
        this.$router.push({name: 'call-offer-index'});
    }

    admin(){
        localStorage.setItem('platform', 'admin')
            this.$store.state.platform = 'admin'
        this.$router.push({name: 'admin-index'});
    }

    devis() {
        localStorage.setItem('platform', 'devis')
        this.$store.state.platform = 'devis'
        this.$router.push({name: 'devis-index'});
    }

    sinisters() {
        localStorage.setItem('platform', 'sinisters')
        this.$store.state.platform = 'sinisters'
        this.$router.push({name: 'sinistre-index'});
    }

    mounted() {
        // switch (this.$store.state.platform) {
        //     case 'offers': {
        //         if (this.$route.name !== 'call-offer-index') {
        //             this.$router.push({name: 'call-offer-index'});
        //         }
        //     }
        // }
    }

    get isConnected(): boolean {
        return api.isConnected();
    }
}
