










































































import {Component, Vue} from 'vue-property-decorator';
import Popup from "@/entity/Popup";
import {api} from "@/services/Api";

@Component({})
export default class ResetPassword extends Vue {
    loading = false
    password = ''
    repeated = ''
    uuid = ''

    mounted() {
        this.uuid = this.$route.params.uuid
    }
    async update() {
        if (this.password.length === 0) {
            new Popup('Attention', 'Votre mot de passe est vide', 'warning')
            return
        }
        if (this.password !== this.repeated) {
            new Popup('Attention', 'Les deux mots de passe ne correspondent pas', 'warning')
            return
        }
        this.loading = true

        const res = await api.post('shared/customer/update/password', {
            uuid: this.uuid,
            password: this.password
        })
        if (res && res.data && res.data.status === 'success') {
            new Popup('Succès', 'Votre mot de passe a été mis à jour <br> Connectez vous avec vos nouveaux identifiants', 'success')
            this.$router.push({name: 'login'});
        }
        this.loading = false

    }
}
