

























import {Component, Vue} from 'vue-property-decorator';
import LeftMenu from "@/layouts/Header/LeftMenu.vue";
import RightMenu from "@/layouts/Header/RightMenu.vue";
import {auth} from "@/services/Auth";

@Component({
    components: {LeftMenu, RightMenu}
})
export default class HeaderTop extends Vue {
    public backendUrl = process.env.VUE_APP_BACKEND_URL;

    get isConnected():boolean{
        return auth.isConnected();
    }

}
