

































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CallOffer from "@/entity/CallOffer";

@Component({})
export default class CallOfferResults extends Vue {
    @Prop() callOffer!: CallOffer
}
