






























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CallOffer from "@/entity/CallOffer";
import FileUpload from "@/components/FileUpload.vue";
import File from "@/entity/File";

@Component({
    components: {FileUpload}
})
export default class CallOfferIac extends Vue {
    @Prop() callOffer!: CallOffer

    beforeMount(): void {
        if (!this.callOffer.data.iac) {
            const d = new CallOffer()
            this.callOffer.data.iac = d.data.iac
        }
        if (this.callOffer.data.iac.capitaux.length === 0) {
            const ca = [
                {name: 'Décès Accidentel', selected: false, amount: 0, count: 0},
                {name: 'Incapacité permantente', selected: false, amount: 0, count: 0},
                {name: 'Indemnité journalière', selected: false, amount: 0, count: 0},
                {name: 'Frais médicaux', selected: false, amount: 0, count: 0},
            ]

            this.callOffer.data.iac.capitaux = ca as any;
        }
    }

    updateList(uuid: string) {
        const file = new File()
        file.uuid = uuid;
        this.callOffer.data.iac.list = file as any
        this.$emit('save')
    }

    async enableForm() {
        this.$emit('enableStep', 'rcProOnlineForm')
    }

    disableForm() {
        this.$emit('disableStep', 'rcProOnlineForm')
    }

    async goToForm() {
        await this.enableForm()
        this.next()
    }
    save(){
        this.$emit('save')
    }

    next() {
        this.$emit('next')
    }

    previous() {
        this.$emit('previous')
    }
}
