import Customer from "@/entity/Customer";
import Popup from "@/entity/Popup";
import {api} from "@/services/Api";
import store from "@/store";

class Actions {

    async sendCustomerAccountValidationEmail(popup: Popup, customer: Customer) {
        store.commit('loading')
        const res =await api.get('shared/customer/send/account/validation/mail/' + customer.email)
        // console.log(res)
        popup.hide()
        // console.log(customer)
        store.commit('stopLoading')
    }

}

export const actions = new Actions()
